export const navButton = document.getElementById("nav-button");
export const navOverlay = document.getElementById("nav-overlay");
export const navLinks = document.querySelectorAll(".nav-bar a");
export const navBar = document.querySelector(".nav-background");
export const headerArrow = document.querySelector(".header-arrow");
export const slideUpImg = document.querySelectorAll(".slide-up");
export const landingPage = document.getElementById("home");
export const landingText = document.getElementById("landing-text");
export const landingAttributes = document.getElementById("header-attributes");
export const words = JSON.parse(landingAttributes.getAttribute("data-words"));
export const wait = landingAttributes.getAttribute("data-wait");
export const missionSection = document.getElementById("mission-statement");
export const missionTitle = document.getElementById("mission-title");
